var Events = new Vue();

var Cart = new Vue({
    methods: {
        fetch: function() {
            var cartCookieData = Cookies.get( 'documentCart' );
            var cart = cartCookieData ? JSON.parse( cartCookieData ) : [];
            return cart;
        },
        update: function( data ) {
            Cookies.set( 'documentCart', data );
            Events.$emit( 'cartUpdated', data );
        },
        addItem: function( item ) {
            var fetched = this.fetch();
            if( ! fetched.includes( item ) ) {
                fetched.push( item );
            }
            this.update( fetched );
        },
        removeItem: function( item ) {
            var fetched = this.fetch();
            if( fetched.includes( item ) ) {
                fetched = fetched.filter( function( cartItem ) {
                    return cartItem !== item;
                } );
            }
            this.update( fetched );
        }
    }
});

var CartBase = {
    delimiters: [ '[[', ']]' ],
    data: function() {
        return {
            cart: []
        }
    },
    created: function() {
        var vm = this;
	var syncCart = function() {
	    while( vm.cart.length > 0 ) {
		vm.cart.shift();
	    }
            Cart.fetch().forEach( function( item ) {
		vm.cart.push( item );
	    } );
	};
        syncCart();
        Events.$on( 'cartUpdated', syncCart );
    },
};

new Vue( Object.assign( {}, CartBase, {
    el: '#cartIndicator',
    computed: {
        x: function() {
            return this.cart.length;
        },
    },
} ) );

new Vue( Object.assign( {}, CartBase, {
    el: '#cartOverview',
    created: function() {
        var vm = this;
        CartBase.created.apply( vm );
        jQuery.post( ajaxUrl, {
            action: 'cart_info_request',
            cart: vm.cart.length > 0 ? vm.cart : false
        }, function( result ) {
            result.forEach( function( r ) {
                vm.cartInfo.push( r );
            } );
            vm.loaded = true;
        } );
    },
    data: function() {
        return Object.assign( {
            loaded: false, cartInfo: []
        }, CartBase.data() );
    },
    methods: {
        remove: Cart.removeItem,
        add: Cart.addItem,
        filesize: filesize.partial({ round: 0 }),
        openForm: function() {
            this.cart = Cart.fetch();
            ( function( $ ) {
                $.featherlight( '<div id="DownloadForm"></div>', {
                    beforeClose: function() {
                        $( '.featherlight-content' ).css( { display: 'none' } );
                        $( '#DownloadForm' ).children().appendTo( '.js-download-form' );
                    }
                } );
                $( '.js-download-form' ).children().appendTo( '#DownloadForm' );
            } )( jQuery );
        }
    }
} ) );

var EmailForm = Vue.extend( Object.assign( {}, CartBase, {
    template: '#CartEmailFormTemplate'
} ) );

jQuery( document ).ready( function( $ ) {
    var refreshIcons = function() {
        var cart = Cart.fetch();
        $( '.js-add-to-cart' ).each( function() {
            if( Cart.fetch().includes( $( this ).data( 'id' ) ) ) {
                if( ! $( this ).hasClass( 'jungle-green' ) ) {
                    $( this ).addClass( 'jungle-green' );
                }
            }
            else {
                $( this ).removeClass( 'jungle-green' );
            }
        } );
    };
    refreshIcons();
    $( '.js-add-to-cart' ).each( function() {
        $( this ).click( function() {
            var id   = $( this ).data( 'id' );
            var name = $( this ).data( 'name' );
            var text;
            if( ! Cart.fetch().includes( id ) ) {
                Cart.addItem( id );
                text = '"' + name + '" was added to the download cart.'
            }
            else {
                Cart.removeItem( id );
                text = '"' + name + '" was removed from the download cart.'
            }
            refreshIcons();
            var n = new Noty( {
                text: text
            } );
            n.setTimeout( 5000 );
            n.show();
        } );
    } );
} );
