(function($) {
    var Tools = {
        activateSwatches: function() {
            $(this).click(function() {
                console.log('click');
                var activeClass = "swatches__circle--active";
                var active = !! $(this).hasClass(activeClass);
                if($(this).data('just-one')) {
                    $(this)
                        .parent()
                        .parent()
                        .parent()
                        .parent()
                        .parent()
                        .find('.js-color').removeClass(activeClass);

                    if(!active) $(this).addClass(activeClass);
                }
                else {
                    if(!active) {
                        if($(this).data('no-limit') || $('.' + activeClass).length < 10 ) {
                            $(this).toggleClass(activeClass);
                            console.log($('.' + activeClass).length);
                        }
                    } else {
                        $(this).toggleClass(activeClass);
                        console.log($('.' + activeClass).length);

                    }
                }
            });
        },
        addErrorMsg: function($elem, msg, scroll) {
            var $tmp = $("<div></div>").css({ display: 'none' }).appendTo($("body"));

            if(0 == $elem.find('.js-error-notice').length) {
                $elem.children().appendTo($tmp);

                $elem.append("<div class='border border-err border-width-2'>"
                    + "<span class='block bg-err white p1 mb2 fs-13 font-main'>"
                        + msg
                    + "</span>"
                    + "<div class='js-error-notice'></div>"
                + "</div>");

                $tmp.children().appendTo($elem.find('.js-error-notice'));
            }

            if(scroll) {
                $("html, body").animate({
                    scrollTop: $elem.find('.js-error-notice').offset().top,
                }, function() {
                    $elem.find('.js-error-notice').focus();
                });
            }
        },
   };

    $.getFormTools = function() {

        var Form = function() {
            var field_id = JSON.parse($(".js-field-id").text());
            var email_id = JSON.parse($(".js-email-id").text());
            var form_id  = JSON.parse($(".js-form-id").text());

            var _ = this;

            _.$form = $(".js-main-form");
            _.$gravityForm = $(".js-gravity-form");
            _.$gravityForm.$field = $('#input_' + form_id + '_' + field_id);
            _.$gravityForm.$emailField = $('#input_' + form_id + '_' + email_id);

            _.$productUnfold = $(".js-product-unfold");
            _.$colorUnfold = $(".js-color-unfold");
            _.$litUnfold = $(".js-lit-unfold");

            _.$holder = $("<div></div>").css({ display: 'none' }).appendTo($("body"));

            _.$shipping = _.$form.find('.js-shipping-info');
            _.$addMore  = _.$form.find('.js-add-more');

            _.shippingInfo = new ShippingInfo(this);

            _.products = [];

            _.colors   = null;

            _.literature   = null;

            _.templates = {
                $products: $(".js-products"),
                $colors:   $(".js-colors"),
                $literature:   $(".js-literature"),
            };

            _.getProducts = function() {
                return _.$form.find(".js-products");
            };

            _.getValidData = function() {
                var $products = this.getProducts();
                var okay = false;
                var data = [];

                $products.each(function() {
                    var prod   = $(this).trigger('get');
                    var myData = prod.getValidData();

                    if(myData) {
                        data.push(myData);
                    }

                    else {
                        okay = false;
                    }
                });

                return okay ? data : okay;
            };

            _.setup = function() {
                Object.keys(_.templates).forEach(function(key) {
                    _.templates[key].appendTo(_.$holder);
                });

                _.$addMore.find('.js-another-product').click(function() {
                    new ProductBox(_);
                });

                _.$addMore.find('.js-color-chips').click(function() {
                    new ColorBox(_);
                });
            };

            _.setup();
        };

        var ProductBox = function(form) {
            var _ = this;

            form.products.push(this);

            _.$box = form.templates.$products.clone();
            _.$products = _.$box.find('.js-product');
            _.$swatchesPanel = _.$box.find('.js-swatches-panel');
            _.$cancel = _.$box.find('.js-cancel');

            _.$swatchesPanel.$options  = _.$box.find('.js-swatch-option');
            _.$swatchesPanel.$swatches = _.$box.find('.js-swatches');
            _.$swatchesPanel.$colors   = _.$box.find('.js-color');

            if ($("body").hasClass("page-id-13091")) {
              // Add quantity controls within each ProductBox instance
              _.$quantityContainer = $("<div class='js-quantity-container'></div>").appendTo(_.$box);
              _.$quantityContainer.append('<h3>Quantity:</h3>');
              _.$increment = $('<span type="button" class="item-quantity item-quantity--inc js-quantity-inc"></span>').appendTo(_.$quantityContainer);
              _.$quantityValue = $('<input type="text" class="item-input js-product-quantity" value="1">').appendTo(_.$quantityContainer);
              _.$decrement = $('<span type="button" class="item-quantity item-quantity--desc js-quantity-dec"></span>').appendTo(_.$quantityContainer);

              // Create isolated containers for profiles and finishes within each ProductBox
              _.$profilesContainer = $("<div class='js-profiles'></div>").appendTo(_.$box);
              _.$finishesContainer = $("<div class='js-finishes'></div>").appendTo(_.$box);
            };
            _.getShowSwatches = function() {
                return JSON.parse(_.$box.find(".js-swatch-option:checked").val());
            };

            _.validate = function() {
                return false !== _.getValidData();
            };

            _.getValidData = function() {
                var isOkay  = true;
                var product = null;
                var colors  = [];
                var profiles = null;
                var finishes = null;
                var quantity = null;

                if ($("body").hasClass("page-id-13091")) {
                    profiles = [];
                    finishes = [];
                    quantity = parseInt(_.$quantityValue.val(), 10) || 0;
                }

                _.$box.find(".js-swatch-option:checked").each(function() {
                    if(JSON.parse($(this).val())) {
                        colors = "Default";
                    }
                });

                _.$products.each(function() {
                    if($(this).parent().hasClass('border-jungle-green')) {
                        product = $(this).text().replace(/^\s+/, "").replace(/\s+$/, "");
                    }
                });

                if('object' == typeof(colors)) {
                    _.$swatchesPanel.$colors.each(function() {
                        if($(this).hasClass("swatches__circle--active")) {
                            colors.push($(this).data('name'));
                        }
                    });
                }

                if ($("body").hasClass("page-id-13091")) {
                  // Get selected profiles
                  _.$profilesContainer.find("input[name='profiles[]']:checked").each(function() {
                      profiles.push($(this).val());
                  });

                  // Get selected finishes
                  _.$finishesContainer.find("input[name='finishes[]']:checked").each(function() {
                      finishes.push($(this).val());
                  });
                };

                if(!product || ('object' == typeof(colors) && colors.length == 0)) {
                    isOkay = false;
                }

                return isOkay ? {
                  product: product,
                  colors: colors,
                  quantity: quantity,
                  profiles: profiles,
                  finishes: finishes
                } : isOkay;
            };

            _.remove = function() {
                form.products.splice(form.products.indexOf(_), 1);
                _.$box.remove();
                _.checkCount();
            };

            _.checkCount = function() {

                var product_length = $("body").hasClass("page-id-13091") ? 100 : 5;

                if(form.$form.find('.js-products').length < product_length) {
                    form.$form.find('.js-another-product').css({
                        display: 'block',
                    });
                }

                else {
                    form.$form.find('.js-another-product').css({
                        display: 'none',
                    });
                }
            };

            _.setup = function() {
                _.$box.css({'display': 'block'}).appendTo(form.$productUnfold);

                _.$products.each(function() {
                    $(this).click(function() {
                        _.$products.each(function() {
                            $(this).parent().css({
                                'border-width': '',
                                'margin': '',
                            }).removeClass('border').removeClass('border-jungle-green');
                        });

                        $(this).parent().css({
                            'border-width': '4px',
                            'margin': '-4px',
                        }).addClass('border').addClass('border-jungle-green');

                        // Parse JSON for profiles and finishes
                        var profiles = JSON.parse($(this).attr('data-profiles') || '[]');
                        var finishes = JSON.parse($(this).attr('data-finishes') || '[]');

                        if ($("body").hasClass("page-id-13091")) {
                          // Clear only the current ProductBox instance's options
                          _.$profilesContainer.empty();
                          _.$finishesContainer.empty();
                        };

                        // Display profiles as checkboxes
                        if (Array.isArray(profiles) && profiles.length > 0) {
                            _.$profilesContainer.append('<h3>Profiles:</h3>');
                            profiles.forEach(function(profile) {
                                _.$profilesContainer.append(
                                    '<label><input type="checkbox" name="profiles[]" value="' + profile.profile + '"> ' + profile.profile + '</label><br>'
                                );
                            });
                        }

                        // Display finishes as checkboxes
                        if (Array.isArray(finishes) && finishes.length > 0) {
                            _.$finishesContainer.append('<h3>Finishes:</h3>');
                            finishes.forEach(function(finish) {
                                _.$finishesContainer.append(
                                    '<label><input type="checkbox" name="finishes[]" value="' + finish.finish + '"> ' + finish.finish + '</label><br>'
                                );
                            });
                        }

                        _.$swatchesPanel.css({ display: 'block' });

                        form.$addMore.css({
                            display: 'inline-block'
                        });

                        form.$shipping.css({
                            display: 'block'
                        });

                        var h = _.$box.find(".js-swatches-height").height();
                        var hinner = parseInt(_.$box.find(".js-swatches-height").css('height'));

                        _.$box.find(".js-products-height").css('height', h);
                        _.$swatchesPanel.$swatches.css('height', hinner - _.$box.find(".js-form").outerHeight());
                    });
                });

                if ($("body").hasClass("page-id-13091")) {
                  // Quantity control logic
                  _.$increment.click(function() {
                      var currentVal = parseInt(_.$quantityValue.val(), 10) || 0;
                      _.$quantityValue.val(currentVal + 1);
                  });

                  _.$decrement.click(function() {
                      var currentVal = parseInt(_.$quantityValue.val(), 10) || 0;
                      if (currentVal > 0) {
                          _.$quantityValue.val(currentVal - 1);
                      }
                  });
                };

                _.$swatchesPanel.$options.change(function() {
                    _.$swatchesPanel.$swatches.css({
                        display: _.getShowSwatches() ? "none" : "block"
                    });

                    var h = _.$box.find(".js-swatches-height").height();

                    _.$box.find(".js-products-height").css('height', h);

                });

                _.$swatchesPanel.$options.attr('name', "colors_show_" + form.products.length);

                _.$swatchesPanel.$colors.each(Tools.activateSwatches);

                _.$swatchesPanel.$colors.click(function() {
                    form.$shipping.css({ display: 'block' });
                    form.$addMore.css({ display: 'block' });
                });

                _.$box.on('get', function() {
                    return _;
                });

                _.$cancel.click(_.remove);

                _.checkCount();
            };

            _.setup();
        };

        var ColorBox = function(form) {
            var _ = this;

            form.colors = this;

            _.$box = form.templates.$colors.clone();
            _.$colors = _.$box.find('.js-color');
            _.$checkbox = _.$box.find('.js-color-checkbox');
            _.$cancel = _.$box.find('.js-cancel');
            var checkbox = false;

            _.validate = function() {
                return !! _.getValidData();
            };

            _.getValidData = function() {
                var colors = [];

                if($(".js-color-checkbox__input:checked").length > 0) {
                    colors = ['Sample Kit'];
                    return colors;
                } else {
                    _.$colors.each(function() {
                        if($(this).hasClass("swatches__circle--active")) {
                            colors.push($(this).data('name'));
                        }
                    });
                    return 0 == colors.length ? false : colors;
                }

            };

            _.remove = function() {
                form.colors = null;

                _.$box.remove();

                _.checkCount();
            };

            _.checkCount = function() {
                if(form.$form.find('.js-colors').length > 0) {
                    form.$form.find('.js-color-chips').css({
                        display: 'none',
                    });
                }

                else {
                    form.$form.find('.js-color-chips').css({
                        display: 'block',
                    });
                }
            };

            _.setup = function() {
                _.$box.css({ 'display': 'block'}).appendTo(form.$colorUnfold);
                _.$colors
                    .each(Tools.activateSwatches)
                    .click(function() {
                        form.$shipping.css({ display: _.validate() ? 'block' : 'none' });
                    });

                _.$cancel.click(_.remove);

                _.$checkbox.each(function() {
                    $(this).click(function() {
                        if($(".js-color-checkbox__input:checked").length > 0) {
                            $(".js-color-selector").css("display", "none");
                            form.$shipping.css({ display: 'block' });
                            checkbox = true;
                        } else {
                            $(".js-color-selector").css("display", "block");
                            if(!_.checkCount()) {
                                form.$shipping.css({ display: 'none' });
                            }
                        }
                    });
                });

                _.checkCount();
            };

            _.setup();
        };
        var LitBox = function(form) {

            var _ = this;

            form.literature = this;

            _.$box = form.templates.$literature.clone();
            _.$literature = _.$box.find('.js-single-lit');
            _.validate = function() {
                return !! _.getValidData();
            };

            _.getValidData = function() {
                var lits = [];

                $('.js-lit-quantity').each(function(){
                    if($(this).val() > 0){
                        if($(this).val().length === 0 ) {
                            $(this).val(0);
                            lits = jQuery.grep(lits, function(value) {
                                return value != $(this).attr('name') + " qty: 0";
                            });
                        }
                        lits.push($(this).attr('name') + " qty: " + ($(this).val()));
                    }
                });

                return 0 == lits.length ? false : lits;


            };

            _.remove = function() {
                form.literature = null;

                _.$box.remove();

            };

            _.setup = function() {
                _.$box.css({ 'display': 'block'}).appendTo(form.$litUnfold);
                _.$literature.each(function(){
                    console.log('single-lit');
                    var $litQuantity = $(this).find('.lit__quantity');
                    var $quantityValue = $(this).find('.js-lit-quantity');

                    $litQuantity.each(function(){
                        $(this).click(function(){
                            var val = $quantityValue.val();
                            if($(this).hasClass('lit__quantity--inc')){
                                if(val.length === 0) {
                                    $quantityValue.val(parseFloat(0) + 1);
                                } else {
                                    $quantityValue.val(parseFloat(val) + 1);
                                }
                            }
                            if($(this).hasClass('lit__quantity--desc')){
                                if(val > 0) {
                                    $quantityValue.val(parseFloat(val) - 1);
                                }
                            }
                            _.validate();
                            form.$shipping.css({ display: _.validate() ? 'block' : 'none' });
                        });
                    });
                });
            };

            _.setup();
        };

        var ShippingInfo = function(form) {
            var _ = this;
            var expedited = false;

            _.$shipping = form.$form.find('.js-shipping-info');
            _.$fields   = _.$shipping.find('select, input, textarea');

            _.validate = function() {
                console.log("Running validate!");

                var allRight = true;

                _.$fields.each(function() {
                    var tagName = $(this).prop('tagName');
                    var name = $(this).attr('name');
                    var type = $(this).attr('type');

                    if(name == 'address_street_2') {
                        // this field can be left blank.
                    }

                    else if('INPUT' == tagName
                        && 'radio' == type
                        && "" == $("input[name='" + name + "']").val()) {
                            allRight = false;
                    }

                    else if('TEXTAREA' != tagName && "" == $(this).val()) {
                        if(name == 'shipping_priority' || name == 'fedex_account_no') {
                            if(expedited == true) {
                                allRight = false;
                            }
                        } else {
                            allRight = false;
                        }
                    }
                });



                return allRight;
            };

            _.setup = function(){
                _.$fields.each(function() {
                    if($(this).attr('type') == 'radio'
                    && $(this).attr('name') == 'shipping_type') {
                        $(this).each(function() {
                            $(this).click(function() {
                                if($(this).attr('value') == 'Expedited Shipping') {
                                    $('.js-expedited').removeClass('hide-at-first');
                                    expedited = true;

                                } else {
                                    $('.js-expedited').addClass('hide-at-first');
                                    expedited = false;
                                }
                            });
                        });
                    }
                });
            };

            _.setup();
        };

        return {
            Form: Form,
            ProductBox: ProductBox,
            ColorBox: ColorBox,
            LitBox: LitBox,
        };
    };
})(jQuery);
